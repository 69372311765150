import { getConfig } from "./data";
import { applyStyles } from "./htmlUtils";

export const injectCSS = () => {
  const CSS = `
  .narvar__spinner {
    width: 16px;
    height: 16px;
    border: 3px solid #fff;
    border-bottom-color: transparent !important;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }
    @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .narvar__spinner--dark {
    border-color: #000;
    margin: 4px;
  }
  `;
  const target = document.body;
  const el = document.createElement("style");
  el.innerHTML = CSS;
  target.insertAdjacentElement("afterbegin", el);
  return el;
};

export const createBanner = ({
  message,
  color,
  dismiss = 0,
  target = document.body,
}) => {
  const { branding } = getConfig() ?? {};
  const fixColor = (value, defaultValue) =>
    value ? `#${value}` : defaultValue;
  const bgColor = {
    primary: fixColor(branding?.brand_primary, "#000"),
    secondary: fixColor(branding?.brand_secondary, "#000"),
    error: fixColor(branding?.error_color, "#E63935"),
  };
  const textColor = {
    primary: "#fff",
    secondary: "#fff",
    error: fixColor(branding?.error_text_color, "#fff"),
  };

  const el = document.createElement("div");
  el.className = "narvar__banner";
  el.innerText = message;
  applyStyles(el, {
    width: "100%",
    padding: "25px 5px 25px 5px",
    textAlign: "center",
    fontSize: "18px",
    backgroundColor: bgColor[color] || bgColor.secondary,
    color: textColor[color] || textColor.secondary,
    opacity: 1,
  });

  if (dismiss) {
    applyStyles(el, {
      position: "fixed",
      zIndex: 1000,
      transition: "opacity 0.8s",
    });
    setTimeout(() => {
      el.style.opacity = 0;
      setTimeout(() => el.remove(), 1000);
    }, dismiss * 1000);
  }

  if (typeof target === "string") {
    try {
      target = document.querySelector(target);
    } catch (e) {
      console.error(e);
    }
  }
  if (target) {
    target.insertAdjacentElement("afterbegin", el);
  }
  return el;
};

export const CHECKOUT_CACHE_KEY = "nv_shop_now_checkout";
export const CONFIG_CACHE_KEY = "nv_shop_now_config";
export const DEV_CACHE_KEY = "nv_shop_now_dev";
export const APP_DOMAIN =
  NVO_CONFIG.environment !== "test" // eslint-disable-line no-undef
    ? "https://" + NVO_CONFIG.appProxyDomain // eslint-disable-line no-undef
    : "";
export const PARAM_SHOP_ID = "nrvr_shop";
export const PARAM_CODE = "nrvr_code";
export const PARAM_EXPIRE = "nrvr_expire";
export const PARAM_CURRENCY = "nrvr_currency";
export const PARAM_REFUND_IN_CENTS = "nrvr_cents";
export const PARAM_REFUND_FORMATTED = "nrvr_format";
export const PARAM_CART_ITEMS = "nrvr_add_to_cart";
export const PARAM_ENABLE_ORDER_SUMMARY = "nrvr_enable_order_summary";
export const PARAM_ORDER_SUMMARY_TEXT = "nrvr_order_summary_text";
export const CHECKOUT_SUCCESS_STEP = "thank_you";
export const CHECKOUT_SHIPPING_STEP = "shipping_method";
export const CHECKOUT_PAYMENT_STEP = "payment_method";

// TODO:: ensure this query works across shops
// default selector for checkout button, checkout form and shopping cart form
// selectors (splitted into multiple line for git friendly)
// reference: https://shopify.dev/themes/architecture/templates/cart#proceed-to-checkout
export const CHECKOUT_FORM_SELECTOR = [
  'form[action="cart"]',
  'form[action="/cart"]',
  'form[action="checkout"]',
  'form[action="/checkout"]',
].join(",");

export const CHECKOUT_BUTTON_SELECTOR = [
  "input[name=checkout]",
  "button[name=checkout]",
  'a[href="checkout"]',
  'a[href="/checkout"]',
].join(",");
